//-------------------some settings for which api and firebase to use-------------------
// variables below are used to set the api and firebase to use
// building on localhost will automatically use dev firebase and dev api unless LIVE_OVERRIDE is set to true
//
// if you are building on localhost, default settings will be dev firebase and dev api
// if you are building on vercel, default settings will be live firebase and live api
//
// if you want to force dev firebase and dev api in vft, set dev_firebase_in_preview to true
// if you want to force dev api in vft, set dev_api_in_vft to true
//
// if you want to force live firebase and live api on localhost, set live_firebase_on_localhost to true
// if you want to force live api on localhost, set live_api_on_localhost to true
//
// setting both live and dev to true may cause unexpected behavior
//
//
let live_firebase_on_localhost = false
let live_api_on_localhost = false 
//--------------------------------
let dev_firebase_in_vft = false
let dev_api_in_vft = false
//
let temp_force_enterprise = true
export const FORCE_ENTERPRISE_ID = `TgNi8mpKJTnZ3Lt4Dzfp`
//
//dev firebase testing enterprise : TgNi8mpKJTnZ3Lt4Dzfp
//
//
//
//----------------do not edit below this line----------------
let temp_use_dev_firebase = false
let temp_use_dev_api = false
if(process.env.VUE_APP_MODE === 'preview') {
    temp_use_dev_firebase = false
    temp_use_dev_api = false
}
// if building on localhost, use dev firebase and dev api 
if(process.env.VUE_APP_MODE !== 'production' && process.env.VUE_APP_MODE !== 'preview') {
    temp_use_dev_firebase = true
    temp_use_dev_api = true
}
if(live_firebase_on_localhost) {
    temp_use_dev_firebase = false
}
if(live_api_on_localhost) {
    temp_use_dev_api = false
}
if(dev_firebase_in_vft) {
    temp_use_dev_firebase = true
}
if(dev_api_in_vft) {
    temp_use_dev_api = true
}
// just a check to make sure production is being used! 
// more of a failsafe for the lazy or forgetful
if (process.env.VUE_APP_MODE === 'production') { 
    temp_use_dev_firebase = false
    temp_use_dev_api = false
    temp_force_enterprise = false
  }
export const USE_DEV_FIREBASE = temp_use_dev_firebase 
export const USE_DEV_API = temp_use_dev_api
export const FORCE_ENTERPRISE = temp_force_enterprise
